import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest petit bolet destaca per viure sempre damunt les pinyes i per la base del peu que està coberta de pèls eriçats. El capell és d’1 a 4 cm de diàmetre, acampanat inicialment per passar a convex o quasi pla i de color rosa salmó al bru rogenc. El marge és prim, uniforme i estriat. Les làmines són poc nombroses, de color rosa pàl·lid amb l’aresta del mateix color que el capell. El peu, de 4-8 x 0,1-0,3 cm, és cilíndric, esvelt i lluent. També destaca el gust de nap de la carn. Les espores són blanques en massa, el·lipsoïdals, d’11-14 x 6-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      